import { Fragment,useState } from 'react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}




export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (

<div>


   <div class="bg-white py-24 sm:py-32">

   <div className="mx-auto max-w-2xl text-center">

     <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
       Always ready to help you
     </p>

     <p className="mt-6  mb-20 text-lg leading-8 text-gray-600">
     We work around the clock to assist you. Drop us a message any time,
and we’ll get back to you in seconds!
     </p>
   </div>
     <div class="mx-auto max-w-7xl px-6 lg:px-8">
       <dl class="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
         <div class="mx-auto flex max-w-xs flex-col gap-y-4">
           <dt class="text-base leading-7 text-gray-600">Always available</dt>
           <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">24/7</dd>
         </div>
         <div class="mx-auto flex max-w-xs flex-col gap-y-4">
           <dt class="text-base leading-7 text-gray-600">Avg. response time</dt>
           <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">10 sec</dd>
         </div>
         <div class="mx-auto flex max-w-xs flex-col gap-y-4">
           <dt class="text-base leading-7 text-gray-600">Satisfaction rate</dt>
           <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">99%</dd>
         </div>
       </dl>
     </div>
   </div>

    </div>
  )
}
