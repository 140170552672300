import { Fragment,useState } from 'react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}




export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (

<div>



      <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <h2 className="text-center text-lg font-semibold leading-8 text-gray-900">
                Best Alternative of Superior Email Marketing Solution
              </h2>
              <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">

                <img
                  className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                  src="https://res.cloudinary.com/dttmahifk/image/upload/v1717217525/Brevo-Logo-1_2_srcfia.png"
                  alt="Reform"
                  width={150}
                  height={48}
                />
                <img
                  className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                  src="https://res.cloudinary.com/dttmahifk/image/upload/v1717217927/img_636c1aa39d828_1_kodqh9.png"
                  alt="Tuple"
                  width={158}
                  height={48}
                />
                <img
                  className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                  src="https://res.cloudinary.com/dttmahifk/image/upload/v1717217676/413-4130464_benchmark-logo-email-benchmark-email-logo-hd-png_wnnel2.png"
                  alt="Transistor"
                  width={158}
                  height={48}
                />
                <img
                  className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
                  src="https://res.cloudinary.com/dttmahifk/image/upload/v1717218473/convertkit-long_1_ztkdoq.png"
                  alt="SavvyCal"
                  width={158}
                  height={48}
                />
                <img
                  className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
                  src="https://res.cloudinary.com/dttmahifk/image/upload/v1717218733/sender-logo-default_1_hqfbzp.jpg"
                  alt="Statamic"
                  width={158}
                  height={48}
                />
              </div>
            </div>
          </div>

    </div>
  )
}
