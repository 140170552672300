import { Fragment,useState } from 'react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}




export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (

<div>

   <section className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:px-8">
       <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />
       <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
       <div className="mx-auto max-w-2xl lg:max-w-4xl">
         <img className="mx-auto h-12" src="https://res.cloudinary.com/dttmahifk/image/upload/v1717239718/logo_1_vskwat.png" alt="" />
         <figure className="mt-10">
           <blockquote className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9 ">
             <p className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9 ">
               “We had a great experience with Mailatmars since I started using it a few months ago - the customer service is excellent, the features are easy to use, and the platform is reliable and secure.”
             </p>
           </blockquote>

         </figure>
       </div>
     </section>



    </div>
  )
}
