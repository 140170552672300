import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./main/index.js";
import Test from "./main/test.js";
import Abtest from './product/ab_test.js';
import Batch from './product/batch.js';
import Editor from './product/email-editor.js';
import Drip from './product/drip.js';
import Analytics from './product/analytics.js';
import Solutions from './solutions/index.js';
import Pricing from './plan/index.js';
import Company from './company/index.js';
import Terms from './company/terms.js';
import Privacy from './company/privacy.js';
import Acceptable from './company/accepted-use.js';
import Logout from './main/logout.js';
import Zapier from './integration/zapier.js';
import Automation from './product/automation.js';
import Startup from './solutions/startup.js';
import Blogger from './solutions/blogger.js';

import './App.css';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}></Route>
        <Route path="/products/batch-email-campaign" element={<Batch />}></Route>
        <Route path="/products/email-ab-testing" element={<Abtest />}></Route>
        <Route path="/products/email-editor" element={<Editor />}></Route>
        <Route path="/products/drip-campaign" element={<Drip />}></Route>
        <Route path="/products/email-campaign-analytics" element={<Analytics />}></Route>
        <Route path="/products/marketing-automation" element={<Automation />}></Route>


        <Route path="/solutions/" element={<Solutions />}></Route>
        <Route path="/solutions/startup" element={<Startup />}></Route>
        <Route path="/solutions/blogger" element={<Blogger />}></Route>
        <Route path="/pricing" element={<Pricing />}></Route>
        <Route path="/company" element={<Company />}></Route>
        <Route path="/company/terms/" element={<Terms />}></Route>
        <Route path="/logout" element={<Logout />}></Route>
        <Route path="/company/privacy/" element={<Privacy />}></Route>
        <Route path="/company/acceptable-use/" element={<Acceptable />}></Route>
        <Route path="/integration/zapier" element={<Zapier />}></Route>
        <Route path="/test" element={<Test />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
