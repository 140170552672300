import { Fragment,useState } from 'react'
import {Helmet} from "react-helmet";
import { Dialog, DialogPanel,Disclosure,
DisclosureButton,
DisclosurePanel,
Popover,
PopoverButton,
PopoverGroup,
PopoverPanel,
Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon,ArrowPathIcon,
PaperAirplaneIcon,
CheckBadgeIcon,
CodeBracketSquareIcon,
RectangleStackIcon,
ChartPieIcon,
ArrowDownOnSquareStackIcon,
CursorArrowRaysIcon,
FingerPrintIcon,
SquaresPlusIcon,
CloudArrowUpIcon,
LockClosedIcon,
ServerIcon,CogIcon } from '@heroicons/react/24/outline'

import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const features_new = [
  { name: 'Batch campaign', description: 'Dispatches batches at hourly intervals and has notably enhanced deliverability', href: '/products/batch-email-campaign', icon: RectangleStackIcon },
  { name: 'A/B testing', description: 'Experimenting with variations to optimize performance and engagement', href: '/products/email-ab-testing', icon: CheckBadgeIcon },
  { name: 'Drip campaign', description: 'Set up series of email campaigns and generate qualified leads', href: '/products/drip-campaign', icon: ArrowDownOnSquareStackIcon },
  { name: 'Analytics', description: 'Insights to track performance, engagement, and optimize marketing strategies', href: '/products/email-campaign-analytics', icon: ChartPieIcon },
  { name: 'Automation', description: 'Automate your email workflows and improve efficiency in your marketing campaigns', href: '/products/marketing-automation', icon: CogIcon, new: true }
];


export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (

<div>



   <div className="bg-white py-24 sm:py-32">
     <div className="mx-auto max-w-7xl px-6 lg:px-8">
       <div className="mx-auto max-w-2xl lg:text-center">
         <h2 className="text-base font-semibold leading-7 text-indigo-600">Drive Results</h2>
         <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
           Customizable for Perfect need
         </p>
         <p className="mt-6 text-lg leading-8 text-gray-600">
           Discover campaign types: drip campaign, A/B testing, batch campaign, and re-engagement—tailored to meet all your marketing needs
         </p>
       </div>
       <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
         <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
           {features_new.map((feature) => (
             <div key={feature.name} className="relative pl-16">
               <dt className="text-base font-semibold leading-7 text-gray-900">
                 <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                   <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                 </div>
                 {feature.name}
               </dt>
               <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>

               <div className="mt-3 flex  gap-x-6">

                 <a href={feature.href} className="text-sm text-indigo-900 font-semibold leading-6 ">
                   Learn more <span aria-hidden="true">→</span>
                 </a>
               </div>
             </div>
           ))}
         </dl>
       </div>
     </div>
   </div>

    </div>
  )
}
