import { Fragment,useState } from 'react'
import {Helmet} from "react-helmet";
import type { InputNumberProps } from 'antd';
import { Col, InputNumber, Row, Slider, Space } from 'antd';
import { Dialog, DialogPanel,Disclosure,
DisclosureButton,
DisclosurePanel,
Popover,
PopoverButton,
PopoverGroup,
PopoverPanel,
Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon,ArrowPathIcon,
PaperAirplaneIcon,
CheckBadgeIcon,
CodeBracketSquareIcon,
RectangleStackIcon,
ChartPieIcon,
ArrowDownOnSquareStackIcon,
CursorArrowRaysIcon,
FingerPrintIcon,
SquaresPlusIcon,
CloudArrowUpIcon,
LockClosedIcon,
ServerIcon,
ChevronDoubleRightIcon,
DocumentTextIcon,
BoltIcon,
RectangleGroupIcon,
UsersIcon,
SparklesIcon,
RocketLaunchIcon


 } from '@heroicons/react/24/outline'

import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
import Header from '../component/header.js';
import Pricing from '../component/pricing.js';
import Feature from '../component/feature.js';
import Stats from '../component/stat.js';
import Review from '../component/review.js';
import Cta from '../component/cta.js';
import Footer from '../component/footer.js';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const logo="https://res.cloudinary.com/dttmahifk/image/upload/v1717130110/Untitled_design_3_v14zca.png";

const features = [
  {
    name: 'Responsive email block',
    description:
      'Incorporate responsive email blocks in Mailatmars editor to ensure your content looks great on all devices effortlessly',
    icon: RectangleGroupIcon,
  },
  {
    name: 'Unique 5+ blocks',
    description: 'Header, product showcase, testimonial, social media integration, and footer blocks enhance brand identity and engagement in branded email templates',
    icon: SquaresPlusIcon,
  },
  {
    name: 'Easy integrate social media',
    description: 'Effortlessly incorporate social media icons into your emails using Mailatmars intuitive email editor for enhanced engagement and connectivity.',
    icon: UsersIcon,
  },
  {
    name: 'Test in various plateform',
    description: 'Straightforward test email editor streamlines the process, facilitating quick iteration and optimization for superior campaign results',
    icon: SparklesIcon,
  },
  {
    name: 'No branding in free plan',
    description: 'Access Mailatmars email editor for free, empowering you to design and send professional campaigns without any financial commitment',
    icon: RocketLaunchIcon,
  },
]


const feature_of_template=[
  {
    name: 'Own coded design',
    description:
      'Easily upload your email template code to Mailatmars for seamless integration and personalized campaign design tailored to your brand',
    icon: CodeBracketSquareIcon,
  },
  {
    name: 'In-built text editor',
    description: 'Streamline email creation with Mailatmars simple text editor. Write, edit, and send emails effortlessly for efficient communication with your audience',
    icon: DocumentTextIcon,
  },
  {
    name: 'Pre-built Template',
    description: 'Utilize Mailatmars pre-built email templates for quick and professional campaign creation tailored to your unique messaging needs',
    icon: BoltIcon,
  },
]

const features_new=[
  { name: 'Batch campaign', description: 'dispatches batches at hourly intervals has notably enhanced deliveribility', href: '#', icon: RectangleStackIcon },
  { name: 'A/B testing', description: 'Experimenting with variations to optimize performance and engagement', href: '#', icon: CheckBadgeIcon },
  { name: 'Drip campaign', description: 'Setup series of email campaign and generate qualify leads', href: '#', icon: ArrowDownOnSquareStackIcon },
  { name: 'Analytics', description: 'Insights track performance, engagement, and optimize marketing strategies', href: '#', icon: ChartPieIcon },
]

const products = [
  { name: 'Batch campaign', description: 'dispatches batches at hourly intervals has notably enhanced deliveribility', href: '#', icon: RectangleStackIcon },
  { name: 'A/B testing', description: 'Experimenting with variations to optimize performance and engagement', href: '#', icon: CheckBadgeIcon },
  { name: 'Email editor', description: 'Customize, design, and craft compelling messages effortlessly for campaigns', href: '#', icon: CodeBracketSquareIcon },
  { name: 'Drip campaign', description: 'Setup series of email campaign and generate qualify leads', href: '#', icon: ArrowDownOnSquareStackIcon },
  { name: 'Analytics', description: 'Insights track performance, engagement, and optimize marketing strategies', href: '#', icon: ChartPieIcon },
]
const callsToAction = [
  { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
  { name: 'Contact sales', href: '#', icon: PhoneIcon },
]


export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  
  return (
    <div className="bg-white">

  <Header />


  <Pricing />



  <Feature />


<Stats />

<Review />

    <Cta />

<Footer />
 <Helmet>
<script id="messenger-widget-b" src="https://cdn.botpenguin.com/website-bot.js" defer>665be91d38f8120275afa983,665be8fe8b01fbfc8fee51ed</script>
 </Helmet>
    </div>
  )
}
