import { Fragment,useState } from 'react'
import {Helmet} from "react-helmet";
import { Dialog, DialogPanel,Disclosure,
DisclosureButton,
DisclosurePanel,
Popover,
PopoverButton,
PopoverGroup,
PopoverPanel,
Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon,ArrowPathIcon,
PaperAirplaneIcon,
CheckBadgeIcon,
CodeBracketSquareIcon,
RectangleStackIcon,
ChartPieIcon,
ArrowDownOnSquareStackIcon,
CursorArrowRaysIcon,
FingerPrintIcon,
SquaresPlusIcon,
CloudArrowUpIcon,
LockClosedIcon,
ServerIcon,CogIcon } from '@heroicons/react/24/outline'

import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const logo="https://res.cloudinary.com/dttmahifk/image/upload/v1717130110/Untitled_design_3_v14zca.png";

const features = [
  {
    name: 'Upload list',
    description:
      'Effortlessly import your entire list, including additional columns, with just one click. Streamline your data management process and get started with ease.',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'Own domain',
    description: 'Enhance your email reputation by using your own domain. Build trust with recipients and improve deliverability by aligning your emails with your brand identity.',
    icon: LockClosedIcon,
  },
  {
    name: 'Email editor',
    description: 'Enjoy the convenience of our responsive email editor built directly into our platform, ensuring your emails look great on any device.',
    icon: CodeBracketSquareIcon,
  },
]



const products = [
  { name: 'Batch campaign', description: 'Dispatches batches at hourly intervals and has notably enhanced deliverability', href: '/products/batch-email-campaign', icon: RectangleStackIcon },
  { name: 'A/B testing', description: 'Experimenting with variations to optimize performance and engagement', href: '/products/email-ab-testing', icon: CheckBadgeIcon },
  { name: 'Email editor', description: 'Customize, design, and craft compelling messages effortlessly for campaigns', href: '/products/email-editor', icon: CodeBracketSquareIcon },
  { name: 'Drip campaign', description: 'Set up series of email campaigns and generate qualified leads', href: '/products/drip-campaign', icon: ArrowDownOnSquareStackIcon },
  { name: 'Analytics', description: 'Insights to track performance, engagement, and optimize marketing strategies', href: '/products/email-campaign-analytics', icon: ChartPieIcon },
  { name: 'Automation', description: 'Automate your email workflows and improve efficiency in your marketing campaigns', href: '/products/marketing-automation', icon: CogIcon }
];

const callsToAction = [
  { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
  { name: 'Contact sales', href: '#', icon: PhoneIcon },
]


export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (

<div>
    <header className="bg-white">
    <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
      <div className="flex lg:flex-1" >
        <a href="/" className="-m-1.5 p-1.5">
          <span className="sr-only">Mailatmars</span>
          <img className="h-10 w-auto" src={logo} alt="" />
        </a>
      </div>
      <div className="flex lg:hidden z-40">
        <button
          type="button"
          className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          onClick={() => setMobileMenuOpen(true)}
        >
          <span className="sr-only">Open main menu</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <PopoverGroup className="hidden lg:flex lg:gap-x-12  z-40">
      <Popover className="relative">
    <PopoverButton className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
      Product
      <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
    </PopoverButton>

    <Transition
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
    >
      <PopoverPanel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-4xl overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
        <div className="p-4 grid grid-cols-2 gap-x-6 gap-y-4">
          {products.map((item) => (
            <div
              key={item.name}
              className="group relative flex items-center gap-x-4 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
            >
              <div className="flex h-12 w-12 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
              </div>
              <div className="flex-auto">
                <a href={item.href} className="block font-semibold text-gray-900">
                  {item.name}
                  {item.name === 'Automation' && (
                    <span className="ml-2 inline-flex items-center justify-center rounded-full bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-800">
                      New
                    </span>
                  )}
                </a>
                <p className="mt-1 text-gray-600">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </PopoverPanel>
    </Transition>
  </Popover>



        <a href="/solutions/" className="text-sm font-semibold leading-6 text-gray-900">
          Solutions
        </a>
        <a href="/integration/zapier" className="text-sm font-semibold leading-6 text-gray-900">
          Integration
        </a>
        <a href="/pricing" className="text-sm font-semibold leading-6 text-gray-900">
        Pricing
        </a>
        <a href="/company" className="text-sm font-semibold leading-6 text-gray-900">
          Company
        </a>
      </PopoverGroup>


      <div className="hidden z-40  lg:flex lg:flex-1 lg:justify-end items-center  justify-center gap-x-6">

        <a href="https://account-v2.mailatmars.com/account/login" className="text-sm font-semibold leading-6 text-gray-900">
          Log in <span aria-hidden="true">→</span>
        </a>
        <a
          href="https://account-v2.mailatmars.com/account/create"
          className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Get started
        </a>
      </div>
    </nav>
    <Dialog className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
      <div className="fixed inset-0 z-10" />
      <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
        <div className="flex items-center justify-between">
          <a href="#" className="-m-1.5 p-1.5">
            <span className="sr-only">Mailatmars</span>
            <img
              className="h-8 w-auto"
              src="https://res.cloudinary.com/dttmahifk/image/upload/v1717130110/Untitled_design_3_v14zca.png"
              alt=""
            />
          </a>
          <button
            type="button"
            className="-m-2.5 rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(false)}
          >
            <span className="sr-only">Close menu</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="mt-6 flow-root">
          <div className="-my-6 divide-y divide-gray-500/10">
            <div className="space-y-2 py-6">
              <Disclosure as="div" className="-mx-3">
                {({ open }) => (
                  <>
                    <DisclosureButton className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                      Product
                      <ChevronDownIcon
                        className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                        aria-hidden="true"
                      />
                    </DisclosureButton>
                <DisclosurePanel className="mt-2 space-y-2">
                      {[...products].map((item) => (
                        <DisclosureButton
                          key={item.name}
                          as="a"
                          href={item.href}
                          className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                        >
                          {item.name}
                        </DisclosureButton>
                      ))}
                    </DisclosurePanel>
                  </>
                )}
              </Disclosure>
              <a
                href="/solutions/"
                className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
              >
                Solutions
              </a>

              <a
                href="/integration/zapier"
                className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
              >
                Integration
              </a>
              <a
                href="/pricing"
                className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
              >
                Pricing
              </a>
              <a
                href="/company"
                className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
              >
                Company
              </a>
            </div>
            <div className="py-6">
              <a
                href="https://account-v2.mailatmars.com/account/login"
                className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
              >
                Log in
              </a>
              <a
                href="https://account-v2.mailatmars.com/account/create"
                className="rounded-md block bg-indigo-600 px-3 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Get started
              </a>
            </div>
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  </header>






 <Helmet>
<script id="messenger-widget-b" src="https://cdn.botpenguin.com/website-bot.js" defer>665be91d38f8120275afa983,665be8fe8b01fbfc8fee51ed</script>
 </Helmet>

    </div>
  )
}
